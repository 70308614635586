<template>
    <div class="map">
        <v-snackbar v-model="snackbar" :timeout="timeoutSnackbar" top color="danger">
            <div>{{ snackbarMessage }}</div>
            <template v-slot:action="{ attrs }">
                <v-btn color="" text v-bind="attrs" @click.prevent="snackbar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>

        <l-map :maxZoom="maxZoom" :zoom="zoom" :minZoom="minZoom" :key="mapKey" :center="center" @click="onMapClick"
            ref="map" @ready="onMapReady">
            <l-tile-layer :options="{ maxZoom: maxZoom }" :url="tileLayerUrl"
                :attribution="currentLayer.attribution"></l-tile-layer>
            <l-control class="topright-fullScreenMap" v-if="fromViewTree">
                <div class="fullscreen">
                    <button class="btn-fullscreen" @click.prevent="fullScreenMap">
                        <img class="img-fullscreen" src="../../../../assets/images/fullscreen.svg" alt="location"
                            width="31" height="31" />
                    </button>
                </div>
            </l-control>
            <l-control class="layer-control-tree-map">
                <div class="change-layer">
                    <select class="layer-select" v-model="selectedOption" @change="updateTileLayerUrl">
                        <option class="item-select" v-for="(option, index) in options" :key="index" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
            </l-control>
            <l-control class="topright" v-if="isCreatingPolygon">
                <div class="undo-redo-clean">
                    <button class="btn" @click="resetMap">
                        <img width="20" height="18" src="../../../../assets/images/clear-points.png" alt="location" />
                        <span>reset</span>
                    </button>
                    <button class="btn" @click="undoPolygon">
                        <img src="../../../../assets/images/undo.png" alt="add-zones" />
                        <span>undo</span>
                    </button>

                    <button class="btn" @click="redoPolygon">
                        <img src="../../../../assets/images/redo.png" alt="location" />
                        <span>redo</span>
                    </button>
                    <button class="btn" @click="closeIsCreatingPolygonMode">
                        <img src="../../../../assets/images/close_white_border.png" alt="location" style="width: 34px; height: 34px; margin-top: 5px;"/>
                        <span></span>
                    </button>
                </div>
            </l-control>
            <l-control-zoom />
            <l-control class="my-controls" v-if="!fromViewTree">
                <button class="location" @click="goToMyLocation">
                    <img src="../../../../assets/images/my-location.png" alt="location" />
                </button>
                <button class="add-points" @click="togglePolygonCreationMode">
                    <img src="../../../../assets/images/add-zones.png" alt="add-zones" />
                </button>
            </l-control>
            <l-layer-group>
                <l-polygon v-for="(swiss,index) in switzerlandCities" :key="`city-${index}`" :lat-lngs="[swiss.coordinates]"> 
                    <l-tooltip class="citites-tooltip" :options="{permanent: true, direction: 'center'}">{{ swiss.city }}</l-tooltip>
                </l-polygon>
                <l-polygon v-for="(zone, index) in mapRegions" :key="`inventar${index}`" :lat-lngs="zone.coordinates"
                    :color="color" :options="polygonOptions">
                    <!-- <l-tooltip ref="tooltip">{{ zone.inventoryId }}</l-tooltip> -->
                    <!-- <l-popup
            ref="popup"
            :content="JSON.stringify(zone.name)"
            :options="popupOptions"
          ></l-popup> -->
                    <l-tooltip :options="{ permanent: true, direction: 'center' }">{{ zone.name }}</l-tooltip>
                </l-polygon>
                <l-polygon v-for="(zone, index) in AreaRegions" :key="`area${index}`" :lat-lngs="zone.coordinates"
                    :color="zone.colorTypeId" :fill-color="zone.colorTypeId" :options="polygonOptionsArea">
                    <!-- <l-tooltip ref="tooltip">{{ zone.inventoryId }}</l-tooltip> -->
                    <!-- <l-popup
            ref="popup"
            :content="JSON.stringify(zone.name)"
            :options="popupOptions"
          ></l-popup> -->
                    <l-tooltip :options="{ permanent: true, direction: 'center' }">{{ zone.name }}</l-tooltip>
                </l-polygon>
                <l-polygon v-if="zoom >= 16" v-for="(zone, index) in treeRegions" :key="`tree${index}`"
                    :lat-lngs="zone.coordinates" :color="zone.colorTypeId" :fill-color="zone.colorTypeId"
                    :options="getPolygonOptions(zone.crownDiameter, zone.id)">
                    <l-tooltip :options="{ permanent: true, direction: 'center', className: 'custom-polygon' }">{{
                        zone.name }} </l-tooltip>
                </l-polygon>

                <l-polyline v-if="polygonPoints.length > 1" :lat-lngs="polygonPoints" :color="color"
                    :options="polylineOptions">
                    <template v-for="(point, index) in polygonPoints">
                        <l-marker :key="'marker-' + index" :lat-lng="point" :draggable="true"
                            @dragend="(event) => onMarkerDragEnd(event, index)">
                            <l-icon :icon-size="[25, 41]" :icon-anchor="[13, 41]"
                                :icon-url="'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png'">
                                <div style="
                    font-size: 8px;
                    color: white;
                    background: black;
                    text-align: center;
                    line-height: 1.5;">
                                    {{ index + 1 }}
                                </div>
                            </l-icon>
                        </l-marker>
                    </template>
                </l-polyline>

                <template v-for="(point, index) in markers">
                    <l-marker :lat-lng="point" :draggable="false" class="markerTree">
                        <l-icon :icon-size="[25, 41]" :icon-anchor="[13, 41]"
                            :icon-url="require('@/assets/images/pin-map.svg')">
                        </l-icon>
                    </l-marker>
                </template>

            </l-layer-group>
        </l-map>
    </div>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    LMap,
    LTileLayer,
    LPolygon,
    LTooltip,
    LLayerGroup,
    LIcon,
    LPopup,
    LDrawToolbar,
    LControlZoom,
    LControl,
    LMarker,
    LPolyline
} from "vue2-leaflet";
import axios from "axios";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet/dist/leaflet.css";
import {
    Icon
} from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw";
import L from "leaflet";
import * as turf from '@turf/turf';

export default {
    components: {
        LMap,
        LTileLayer,
        LPolygon,
        LTooltip,
        LLayerGroup,
        LIcon,
        LPopup,
        LDrawToolbar,
        LControlZoom,
        LControl,
        LMarker,
        LPolyline
    },
    props: ['centerr', 'inventarCoordinates', 'treeCoordinates', 'whichForm', 'from', 'fromViewTree', 'treeId', 'isTree', 'areaId', 'taskId', 'year'],
    data() {
        return {
            centerMapDynamically: null,
            maxZoom: 20,
            minZoom: 9,
            mapKey: 0,
            center: [51.49, -0.05], // set your initial center
            url: `https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=kOlaYPuPlEZLGataPQ1r`, // set your tile layer URL

            // https://api.maptiler.com/maps/cadastre-satellite/{z}/{x}/{y}.png?key=kOlaYPuPlEZLGataPQ1r
            apikey: "kOlaYPuPlEZLGataPQ1r",
            tileLayerUrl: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg',
            currentLayer: {
                name: "",
                baseUrl: 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg', // Replace with your WMS service URL
                cadastarUrl: 'https://wmts.geo.admin.ch/1.0.0/ch.kantone.cadastralwebmap-farbe/default/current/3857/{z}/{x}/{y}.png', // Replace with your WMS service URL
                apikey: "kOlaYPuPlEZLGataPQ1r",
            },
            selectedOption: 'Standard',
            options: [
                'Standard',
                'Cadastral'
            ],
            clickLatLngs: [],
            isCreatingPolygon: false,
            polygonPoints: [],
            redoPolygonPoints: [],
            mapRegions: [],
            AreaRegions: [],
            treeRegions: [],
            polygonOptions: {
                fillColor: "transparent",
                color: "#494949",
                weight: 3,
                opacity: 0.6,
                fillOpacity: 0.6,
            },
            polygonOptionsArea: {
                weight: 1,
                border: "3px solid #A4A4A4",
                opacity: 0.6,
                fillOpacity: 0.6,
            },
            polygonOptionsTree: {
                fillColor: "#A4A4A4",
                color: "#A4A4A4",
                weight: 200,
                border: "3px solid #A4A4A4",
                opacity: 0.6,
                fillOpacity: 0.6
            },
            polylineOptions: {},
            popupOptions: {
                closeButton: false,
                autoClose: false,
                autoOpenPopup: true,
            },
            color: "#A4A4A4",
            layers: [{
                name: "Regions",
                active: true,
                leafletLayer: null,
                layer: L.layerGroup(),
            },],
            zoom: 0, // Initial zoom
            markers: [],
            markerAdded: false,
            formType: "",
            skip: 0,
            take: 300,
            timeoutSnackbar: 2000,
            snackbar: false,
            snackbarMessage: "",
            currentTreeCoordinate: null,
            allIterationsDone:false
        };
    },
    computed: {
        ...mapGetters(["mapRegionData", "error", "loggedInUser", "inventarId","switzerlandCities","getTreeCoordinates"]),
        checkForm() {
            if (this.whichForm == "bereich") {
                this.markers = []
            }
        },
        changeLayerStyle() {
            if (this.$route.name === 'BaumProfil') {
                return { right: '35px' };
            } else {
                return { right: '-10px' };
            }
        },
        },
        methods: {
            updateTileLayerUrl() {
                if (this.selectedOption === 'Cadastral') {
                    this.tileLayerUrl = this.currentLayer.cadastarUrl;
                    this.centerMapOnZoneFromIn(this.currentTreeCoordinate)
                } else {
                    this.tileLayerUrl = this.currentLayer.baseUrl;
                    this.centerMapOnZoneFromIn(this.currentTreeCoordinate)
                }
                this.mapKey++;
            },
            getPolygonOptions(size, id) {
                let scaledSize = 25;

                switch (true) {
                    case size > 18:
                        scaledSize = size * 2;
                        break;
                    case size >= 12 && size <= 18:
                        scaledSize = size * 3;
                        break;
                    case size >= 8 && size < 12:
                        scaledSize = size * 4;
                        break;
                    case size >= 5 && size < 8:
                        scaledSize = size * 6;
                        break;
                    case size >= 2 && size < 5:
                        scaledSize = size * 10;
                        break;
                    default:
                        scaledSize = size * 20;
                }

                const strokeWidth = id === this.clickedZoneId ? 3 : scaledSize;
                return {
                    fillColor: '#fff',
                    color: this.color,
                    weight: scaledSize, // Bind weight property to a dynamic value (e.g., size)
                    border: `${3}px solid ${this.color}`, // Bind border property to a dynamic value
                    opacity: 0.6,
                    fillOpacity: 0.6,
                    // className: "custom-polygon",
                };
            },
            onMarkerDragEnd(event, index) {
                // this.$set(this.polygonPoints, index, latlng);
                const latlng = event.target.getLatLng();
                const newLat = latlng.lat;
                const newLng = latlng.lng;
                this.$set(this.polygonPoints, index, [newLat, newLng]);
                this.savePolygon();
            },
            goToMyLocation() {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position) => {
                        const latlng = [position.coords.latitude, position.coords.longitude];
                        this.$refs.map.mapObject.setView(latlng, 18); // Set the map view to your location with zoom level 15
                    });
                } else {
                    alert("Geolocation is not supported by this browser.");
                }
            },

            onMapReady() {
                this.mapReady = true;
                // this.centerMapOnZoneFromIn();
            },
            onMapClick(event) {

                if (this.whichForm == "baum") {
                    if (this.isCreatingPolygon) {
                        const latlng = event.latlng;
                        this.markers.push([latlng.lat, latlng.lng]);
                        if (this.markers.length > 1) {
                            this.markers.shift();
                        }
                        this.saveMarker([latlng.lat, latlng.lng]);
                        this.markerAdded = true;
                        // this.getTreeMapRegionsFromClickOfUser([latlng.lat, latlng.lng])
                    }
                } else {
                    if (this.isCreatingPolygon) {
                        this.markers = []
                        const latlng = event.latlng;
                        this.polygonPoints.push([latlng.lat, latlng.lng]);
                        if (this.polygonPoints.length > 1) {
                            const lastIndex = this.polygonPoints.length - 1;
                            const lastPoint = this.polygonPoints[lastIndex];
                            const previousPoint = this.polygonPoints[lastIndex - 1];
                            const polyline = L.polyline(
                                [previousPoint, lastPoint],
                                this.polylineOptions
                            );
                            polyline.addTo(this.$refs.map.mapObject);
                            this.savePolygon();
                        }
                    }
                }

            },
            saveMarker(marker) {
                // Save the marker or send its coordinates to the database
                // You can modify this method to suit your needs
                this.$emit("latlngs-updated", marker);
                this.markers.push(marker)

            },
            resetMap() {
                const map = this.$refs.map.mapObject;
                map.eachLayer((layer) => {
                    if (layer instanceof L.Polyline) {
                        const latlngs = layer.getLatLngs().flat();
                        const coords = this.polygonPoints.map((point) => L.latLng(point));
                        if (JSON.stringify(latlngs) === JSON.stringify(coords)) {
                            map.removeLayer(layer);
                        }
                    }
                });

                // Reset the polygon points array
                this.polygonPoints = [];
                this.$emit("latlngs-updatedArea", this.polygonPoints);
                this.savePolygon();
            },
            undoPolygon() {
                if (this.polygonPoints.length === 0) {
                    return;
                }

                const map = this.$refs.map.mapObject;

                // Remove the last polyline from the map
                let lastPolyline;
                map.eachLayer((layer) => {
                    if (layer instanceof L.Polyline) {
                        lastPolyline = layer;
                    }
                });
                if (lastPolyline) {
                    map.removeLayer(lastPolyline);
                }

                // Store the last removed polygon in a variable for redo functionality
                const lastPolygonPoints = this.polygonPoints.pop();

                // Update the polygonPoints array and emit the updated value
                this.$emit("latlngs-updatedArea", this.polygonPoints);

                // Optionally, you can also call this.savePolygon() here to update your data model.

                // Implement redo functionality by storing the last removed polygon in a variable
                // and adding it back to the map.
                this.redoPolygonPoints.push(lastPolygonPoints);
                this.savePolygon();
            },
            redoPolygon() {
                if (this.redoPolygonPoints && this.redoPolygonPoints.length > 0) {
                    const map = this.$refs.map.mapObject;

                    // Get the last removed polygon from the redoPolygonPoints array
                    const lastPolygonPoints = this.redoPolygonPoints.pop();

                    if (lastPolygonPoints) {
                        // Add the polygon back to the polygonPoints array and emit the updated value
                        this.polygonPoints.push(lastPolygonPoints);
                        this.$emit("latlngs-updatedArea", this.polygonPoints);

                        // Create a polyline for the last polygon and add it to the map
                        const polyline = L.polyline(lastPolygonPoints, this.polylineOptions);
                        polyline.addTo(map);
                        this.savePolygon();
                    }
                }
            },

            togglePolygonCreationMode() {
                this.isCreatingPolygon = !this.isCreatingPolygon;
                if (this.isCreatingPolygon) {
                    this.polygonPoints = [];
                }
            },
            closeIsCreatingPolygonMode() {
                this.isCreatingPolygon = false;
            },
            fullScreenMap() {
                this.$router.push({
                    name: "TreeList",
                    params: { isTree: this.isTree, treeId: this.treeId, areaId: this.areaId, coord: this.inventarCoordinates, treeCord: this.treeCoordinates, from: this.from, taskId: this.taskId, year: this.year }
                });
            },
            savePolygon() {
                // Send the polygonPoints array to the database
                // and store the new polygon in this.mapRegions
                this.mapRegions.push({
                    coordinates: this.polygonPoints,
                });

                this.$emit("latlngs-updatedArea", this.polygonPoints);
            },
            handeldata(data) {
                this.mapKey += 1;
                setTimeout(() => {
                    this.center = data;
                }, 100);
            },
            ...mapActions(["mapRegion", "addTreeCoordinates"]),
            async setMapDataRegions() {
                await axios
                    .get("https://tilia-ch.rrota.org/api/Inventory/MapRegion")
                    .then((response) => {
                        this.mapRegions = response.data;

                    })
                    .catch((error) => {
                        // Handle Errors here.
                        // var errorCode = error.code;
                        // var errorMessage = error.message;
                        console.log(error);

                        // ...
                    });
            },
            async setMapDataAreaRegions() {
                await axios
                    .get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}`)
                    .then((response) => {

                        this.AreaRegions = response.data.areaRegions;

                    })
                    .catch((error) => {
                        // Handle Errors here.
                        // var errorCode = error.code;
                        // var errorMessage = error.message;
                        console.log(error);

                        // ...
                    });
            },

            async setMapDataTreeRegions() {
                this.loading = true;
                let iterations = 9; // Number of iterations to perform
                let allIterationsDone = false;

                for (let index = 0; index < iterations; index++) {
                    try {
                        const response = await axios.get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}?skip=${this.skip}&take=${this.take}`);
                        if (response.status === 200) {
                            const treeRegions = response.data.treeRegions;
                            if (treeRegions.length === 0) {
                                console.log("u ndal");
                                allIterationsDone = true;
                                break; // Stop the loop if the response is an empty array
                            }
                            console.log("heci te posht");
                            let data = treeRegions.map(region => ({
                                ...region,
                                coordinates: [region.coordinates], // Convert single coordinate pair to an array of arrays
                            }));

                            this.skip += this.take;
                            this.treeRegions = [...this.treeRegions, ...data];
                        }
                    } catch (error) {
                        console.log(error);
                        // Continue to the next iteration if an error occurs
                    }

                    if (allIterationsDone) {
                        break; // Stop the loop if all iterations are done
                    }
                }

                this.allIterationsDone = allIterationsDone;
                this.loading = false;
            }
            ,

            //function to get the trees with meter distanc one click on the zone
            // async setMapDataTreeRegions() {  
            //         if(this.from == 'edit' || this.from == 'baumProfil'){
            //             try {
            //         const response = await axios.get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}`);
            //         const treeRegions = response.data.treeRegions;
            //         const centerCoordinate = this.treeCoordinates;
            //         const radius = 50; // Radius in meters

            //         // Filter treeRegions based on nearest polygons
            //         const filteredTreeRegions = treeRegions.filter(tree => {
            //         const treeCoordinate = tree.coordinates;
            //         const distance = turf.distance(centerCoordinate, treeCoordinate, { units: 'meters' });
            //         return distance <= radius;
            //         });

            //         // Convert single coordinate pair to an array of arrays
            //         const data = filteredTreeRegions.map(region => {
            //         return {
            //             ...region,
            //             coordinates: [region.coordinates],
            //         };
            //         });

            //         this.treeRegions = data;
            //     } catch (error) {
            //         console.log(error);
            //     }
            //         } else  {
            //             try {
            //                 const response = await axios.get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}`);
            //                 const treeRegions = response.data.treeRegions;
            //                 let last100Objects;

            //                 if (treeRegions.length > 100) {
            //                     // Use slice to get the last 100 objects
            //                      last100Objects = treeRegions.slice(-100);

            //                     // Now last100Objects contains the last 100 objects in the array
            //                     console.log('lastttt',last100Objects);
            //                     } else {
            //                     // If there are 100 or fewer objects, use the entire array
            //                     last100Objects = treeRegions
            //                 }

            //                 // Convert single coordinate pair to an array of arrays
            //                 const data = last100Objects.map(region => {
            //                 return {
            //                     ...region,
            //                     coordinates: [region.coordinates],
            //                 };
            //                 });

            //                 this.treeRegions = data;
            //             } catch (error) {
            //                 console.log(error);
            //             }

            //         }
            //     },

            // async getTreeMapRegionsFromClickOfUser(latlng) {
            //     if(this.from == 'create'){
            //         try {
            //     const response = await axios.get(`https://tilia-ch.rrota.org/api/Tree/MapRegion/${this.inventarId}`);
            //     const treeRegions = response.data.treeRegions;
            //     const centerCoordinate = latlng;
            //     const radius = 50; // Radius in meters

            //     // Filter treeRegions based on nearest polygons
            //     const filteredTreeRegions = treeRegions.filter(tree => {
            //     const treeCoordinate = tree.coordinates;
            //     const distance = turf.distance(centerCoordinate, treeCoordinate, { units: 'meters' });
            //     return distance <= radius;
            //     });

            //     // Convert single coordinate pair to an array of arrays
            //     const data = filteredTreeRegions.map(region => {
            //     return {
            //         ...region,
            //         coordinates: [region.coordinates],
            //     };
            //     });
            //     this.treeRegions = data;
            // } catch (error) {
            //     console.log(error);
            // }
            //     } else {
            //         return
            //     }
            // },

            centerMapOnZoneFromIn(singleTreeCoordinate) {
                if(singleTreeCoordinate.length > 0){
                    const latlngs = [singleTreeCoordinate];
                    const latlngBounds = L.latLngBounds(latlngs);
                    const center = latlngBounds.getCenter();

                    // Use Leaflet's fitBounds method to set the map view to display the entire zone
                    this.$refs.map.mapObject.fitBounds(latlngBounds);

                    // Update the component's center and zoom properties to reflect the new map view
                    this.center = [center.lat, center.lng];
                    this.zoom = [singleTreeCoordinate] ? this.$refs.map.mapObject.getZoom() : 14;
                } else {
                    this.centerMapOnZoneFromInFromCreateTree()   
                }
            },

            centerMapOnZoneFromInFromCreateTree() {

                    const inventarCoord = this.$route.params.inventarCoord;

                    const latlngs = JSON.parse(inventarCoord ? inventarCoord : "[[47.211785,7.541221],[47.211811,7.541753],[47.211541,7.541522]]");
                    const latlngBounds = L.latLngBounds(latlngs);
                    const center = latlngBounds.getCenter();
    
                    // Use Leaflet's fitBounds method to set the map view to display the entire zone
                    this.$refs.map.mapObject.fitBounds(latlngBounds);
    
                    // Update the component's center and zoom properties to reflect the new map view
                    this.center = [center.lat, center.lng];
                    this.zoom = latlngs ? this.$refs.map.mapObject.getZoom() : 14;
                
            },
            onZoomEnd() {
                this.zoom = this.$refs.map.mapObject.getZoom(); // Update the zoom property
                this.updateTooltipFontSize(); // Update the font size of the tooltip
            },
            updateTooltipFontSize() {
                const customPolygons = document.querySelectorAll(".custom-polygon");
                customPolygons.forEach((polygon) => {
                    const tooltip = polygon.querySelector(".leaflet-tooltip");
                    if (tooltip) {
                        const fontSize = Math.round(polygon.style.weight * 0.1); // Calculate the font size based on the polygon's weight
                        tooltip.style.fontSize = `${fontSize}px`; // Set the font size of the tooltip
                    }
                });
            },
            handleCustomForm(form) {
                this.formType = form
            }
        },
        created() {

            this.setMapDataAreaRegions()
            this.setMapDataRegions();
        },


        watch: {
            checkForm(newVal) {
                return newVal
            }
        },
        beforeUnmount() {
            // Remove the zoomend event listener before unmounting the component
            this.$refs.map.mapObject.off("zoomend", this.onZoomEnd);
        },
    mounted() {
        console.log("isTree",this.isTree);
        console.log("treeCoordinates",this.treeCoordinates);

        const coordinateOfTree = this.$route.params.coord == undefined ? [] : this.$route.params.coord;
        if (this.$route.name == "Neuer Baum" && (this.getTreeCoordinates == null || this.getTreeCoordinates[0] == 0)) {
            this.centerMapOnZoneFromInFromCreateTree()
        } else if (this.$route.name == "Neuer Baum" && this.getTreeCoordinates) {
            this.centerMapOnZoneFromIn([this.getTreeCoordinates])

        } else {
            if (coordinateOfTree.length > 0 && coordinateOfTree[0] !== 0) {
                this.currentTreeCoordinate = [coordinateOfTree[0], coordinateOfTree[1]];
                this.centerMapOnZoneFromIn([coordinateOfTree[0], coordinateOfTree[1]])
            } else if (coordinateOfTree.length == 0 && (this.treeCoordinates && this.treeCoordinates[0] !== 0)) {
                this.centerMapOnZoneFromIn([this.treeCoordinates[0], this.treeCoordinates[1]])
            } else if (this.treeCoordinates && !this.isTree) {
                this.centerMapOnZoneFromIn([this.treeCoordinates[0], this.treeCoordinates[1]])
            }
            else {
                this.snackbarMessage = "Dieser Baum hat keine spezifischen Koordinaten"
                this.snackbar = true;
                this.centerMapOnZoneFromInFromCreateTree()
            }
        }
        if (this.$route.params.coord !== undefined) {
            this.markers.push([coordinateOfTree[0], coordinateOfTree[1]])
        } else {
            this.markers.push([this.treeCoordinates[0], this.treeCoordinates[1]])
        }
        this.setMapDataTreeRegions()


        this.$refs.map.mapObject.on("zoomend", this.onZoomEnd);

        this.$parent.$on("my-event", this.handeldata);
        this.mapRegions.forEach((el, index) => this.$refs.popup[index].openPopup());
    },
    
};
</script>

<style lang="scss">
.map {
    width: 100%;
    height: calc(100vh - 75px);
    .citites-tooltip{
        color: #7f1111;
    }
    .layer-control-tree-map{
        .change-layer {
            position: absolute;
            top: -10px;
            right: 60px;
            width: 150px;
            height: 60px;
            background-color: #2a2b2a;
            opacity: 0.8;
            color: #fff;
        }

    }

    .highlighted {
        fill-opacity: 1 !important;
        stroke-width: 80 !important;
    }

    .custom-icon {
        background-image: none !important;
    }

    .tooltip-content {
        font-size: 12px !important;
    }

    .custom-polygon-highlighted {
        color: blue;
    }

    .custom-polygon {
        color: white !important;
        font-size: 12px !important;
    }

    .topright-fullScreenMap {
        width: 55px;
        height: 50px;
        padding: 8px;
        position: absolute;
        top: -10px;
        right: 0;
        background-color: #2A2B2A;
        opacity: 0.8;
        .fullscreen{
            width: 100% !important;
            height: 100%;
            .btn-fullscreen{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }
    }
    .change-layer{
          position: absolute;
          top: -10px;
          right: 60px;
          width: 130px;
          height: 53px;
          background-color: #2a2b2a;
          opacity: 0.8;
          color: #fff;

          .layer-select{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            text-align: center;
            color: #fff;

            .item-select{
              width: 100%;
              color: #fff;
              font-size: 15px;
              background-color: #2a2b2a;
            }
          }
      }

    .leaflet-control-attribution {
        display: none !important;
    }

    .leaflet-tooltip {
        background-color: transparent;
        border: 0;
        // font-size: 22px;
        box-shadow: none;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        color: #2A2B2A;
    }

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
        background-color: #fff;
    }

    .leaflet-left .leaflet-control-zoom {
        display: none;
    }

    .leaflet-right .leaflet-control-zoom {
        position: relative;
        top: 271px;
        right: -10px;
        background: #2a2b2a;
        opacity: 0.8;
    }

    .leaflet-right .leaflet-control-zoom .leaflet-bar {
        border-radius: none;
    }

    .leaflet-touch .leaflet-bar a {
        background: #2a2b2a;
        opacity: 0.8;
        color: white;
    }

    .my-controls {
        position: relative;
        top: 261px;
        right: -10px;
        /* padding: 1.8px; */
        background: #2a2b2a;
        opacity: 0.8;
        /* border-top-left-radius: 2px; */
        /* border-top-right-radius: 2px; */
        background: #2a2b2a;
        opacity: 0.8;
        /* width: 30px; */
        padding-bottom: 5px;
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;

        button.location {
            color: white;
            width: 34px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            /* text-align: center; */
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 6px;
            border-top: 1px solid #a4a4a4;
            padding-top: 7px;
            cursor: pointer;
        }

        button.add-points {
            border-top: 1px solid #a4a4a4;
            padding-top: 10px;
            cursor: pointer;
        }
    }

    .topright {
        position: relative;
        top: -180px;
        right: -10px;

        .undo-redo-clean {
            background: #2a2b2a;
            opacity: 0.8;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px;
                border-right: 1px solid #a4a4a4;
                padding-right: 10px;
                cursor: pointer;
            }

            .btn:last-child {
                border-right: none;
            }

            span {
                margin-top: 5px;
            }
        }
    }
}
</style>
